import { Project, Time } from "../model";
import { WorkBook, WorkSheet, writeFileXLSX as writeFile, utils } from 'xlsx';
import { format } from "date-fns";

export function calculateAverageNumber(times: Time[]): number {
    const sum = times.map(time => time.duration).reduce((result, current) => {
        return result + current;
    }, 0)
    return sum / (times.length === 0 ? 1 : times.length);
}

export function roundTime(time: number = 0): string {
    return (time / 1000).toFixed(2);
}

export function getCopiedProjectData(projects: Project[] | undefined, projectId: string) {
    const templateProject = projects!.find(project => project.id === projectId)!;
    const templatedProcess = templateProject?.processes?.map((process) => {
        return {
            name: process.name,
            times: [],
            runningTimer: false
        }
    })
    // should never be null
    if (templateProject != null) {
        return { ...templateProject, processes: templatedProcess! };
    }
}

export function exportExcelSheet(project: Project) {
    /* generate worksheet */
    const ws: WorkSheet = utils.aoa_to_sheet(generateSheet(project));

    /* generate workbook and add the worksheet */
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, project.name);

    /* save to file */
    writeFile(wb, `${project.name}.xlsx`);
}

export function generateSheet(project: Project): string[][] {
    return [
        ['Lean Tools'],
        ['Project name:', `${project.name}`],
        ['Line:', `${project.line}`],
        ['Workstation:', `${project.workstation}`],
        ['Product:', `${project.product}`],
        ['Factory:', `${project.factory}`],
        ['', '', '', 'Times (ms)'],
        ['Process', 'Count', 'Average', ...getTimes(project)],
        ...getProcesses(project),
        // start and end duration view
        [''],
        ['Process', ...getTimes(project, true)],
        ...getProcessesStartAndEndDate(project)


    ]
}

function getTimes(project: Project, startAndEndDate: boolean = false): string[] {
    let maxTimes = 0;
    project.processes.forEach((process) => {
        if (process.times.length > maxTimes) {
            maxTimes = process.times.length
        }
    });
    let times = [];
    for (let i = 1; i <= maxTimes; i++) {
        if (startAndEndDate) {
            times.push(`Start time ${i}`);
            times.push(`End time ${i}`);

        } else {
            times.push(`Time ${i}`);
        }
    }
    return times;
}

function getProcesses(project: Project): string[][] {
    return project.processes.map((process) => {
        return [process.name, String(process.times.length), String(Math.trunc(calculateAverageNumber(process.times))), ...process.times.map(time => String(time.duration))]
    })

}
function getProcessesStartAndEndDate(project: Project): string[][] {
    return project.processes.map((process) => {
        const row = process.times.reduce<string[]>((acc, obj) => {
            acc.push(format(obj.startTime, 'dd.MM.yyyy pp'), format(obj.endTime, 'dd.MM.yyyy pp'));
            return acc;
          }, []);
          return [process.name, ...row];
        })
    }

