import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

export interface ConfrimWindowProps {
    state: boolean;
    message: string;
    callbackSuccess: any;
    callbackCancel: any;
}

export function ConfirmWindow({ state, message, callbackSuccess, callbackCancel }: ConfrimWindowProps) {

    return (<Dialog
        open={state}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {message}
        </DialogTitle>
 
        <DialogActions>
            <Button onClick={callbackSuccess}>Ok</Button>
            <Button onClick={callbackCancel} autoFocus>
                Cancel
            </Button>
        </DialogActions>
    </Dialog>)
}