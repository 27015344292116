import { Box, Collapse, IconButton, TableContainer, TextField, Typography } from '@mui/material';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Process, Project, Time } from "./model";
import { calculateAverageNumber, roundTime } from './common/utils';
import { db } from './db/database';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { notify, Toaster } from './common/snackbar';
import { ConfirmWindow, ConfrimWindowProps } from './common/dialog';

export interface ProjectReaderProps {
    currentProject: Project;
    editMode: boolean;
}

export function ProjectReader({ currentProject, editMode }: ProjectReaderProps) {

    const [modalSettings, setModalSettings] = useState<ConfrimWindowProps>({
        message: '',
        callbackSuccess: () => console.log("success"),
        callbackCancel: () => console.log("cancel"),
        state: false
    });

    function createData(
        name: string,
        times: Time[]
    ) {
        return { name, average: calculateAverageNumber(times), count: times.length, times };
    }

    async function updatedProcessName(processName: string, index: number) {
        const updatedProcesses: Process[] = currentProject.processes;
        updatedProcesses[index] = { ...updatedProcesses[index], name: processName };
        const updatedProject: Project = { ...currentProject, processes: updatedProcesses };
        await db.projects.update(updatedProject as Project, updatedProject);
        notify('success', 'Process name updated successfully.')
    }

    async function deleteProcess(index: number) {

        setModalSettings({
            message: `Do you really want to delete process "${currentProject.processes[index].name}"?`,
            state: true,
            callbackSuccess: async () => {
                const updatedProcesses: Process[] = currentProject.processes;
                updatedProcesses.splice(index, 1); // 2nd parameter means remove one item only
                const updatedProject: Project = { ...currentProject, processes: updatedProcesses };
                await db.projects.update(updatedProject as Project, updatedProject);
                setModalSettings({ ...modalSettings, callbackCancel: () => { }, callbackSuccess: () => { }, state: false })
                notify('success', 'Process deleted successfully.');
            },
            callbackCancel: () => {
                setModalSettings({ ...modalSettings, callbackCancel: () => { }, callbackSuccess: () => { }, state: false })
            }
        });
    }

    async function deleteTime(processIndex: number, timeIndex: number) {
        setModalSettings({
            message: `Do you really want to delete time "${roundTime(currentProject.processes[processIndex].times[timeIndex].duration)}"?`,
            state: true,
            callbackSuccess: async () => {
                const updatedProcesses: Process[] = currentProject.processes;
                updatedProcesses[processIndex].times.splice(timeIndex, 1); // 2nd parameter means remove one item only
                const updatedProject: Project = { ...currentProject, processes: updatedProcesses };
                await db.projects.update(updatedProject as Project, updatedProject);
                setModalSettings({ ...modalSettings, callbackCancel: () => { }, callbackSuccess: () => { }, state: false })
                notify('success', 'Time deleted successfully.');
            },
            callbackCancel: () => {
                setModalSettings({ ...modalSettings, callbackCancel: () => { }, callbackSuccess: () => { }, state: false })
            }
        });
    }

    const rows = currentProject.processes.map((process) => {
        return createData(process.name, process.times)
    });

    // TODO: fix type
    function EditableRow(props: any) {
        const { row, index } = props;
        const [open, setOpen] = useState(false);

        return (
            <>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell><button onClick={() => deleteProcess(index)}><DeleteIcon /></button></TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            onBlur={(event) => updatedProcessName(event.target.value, index)} label={''} defaultValue={row.name}>
                        </TextField>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Times
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Action</TableCell>
                                            <TableCell>Time (s)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows[index].times.map((time, i) => (
                                            <TableRow key={i}>
                                                <TableCell><button onClick={() => deleteTime(index, i)}><DeleteIcon /></button></TableCell>
                                                <TableCell component="th" scope="row">
                                                    {roundTime(time.duration)}s
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>)
    }


    return (
        <>
            <Toaster></Toaster>
            <ConfirmWindow
                state={modalSettings.state}
                message={modalSettings.message}
                callbackSuccess={modalSettings.callbackSuccess}
                callbackCancel={modalSettings.callbackCancel}></ConfirmWindow>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {editMode && <>
                                <TableCell>Expand / Collapse times</TableCell>
                                <TableCell>Action</TableCell>
                            </>
                            }

                            <TableCell>Name</TableCell>
                            {!editMode && <>
                                <TableCell>Average</TableCell>
                                <TableCell align="right">Count</TableCell>
                            </>}
                        </TableRow>
                    </TableHead>
                    {editMode ?
                        <TableBody>
                            {rows.map((row, index) => (
                                <EditableRow row={row} index={index} key={index}></EditableRow>
                            ))}
                        </TableBody>
                        : <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <span>{row.name}</span>
                                    </TableCell>
                                    <TableCell>{roundTime(row.average)}s</TableCell>
                                    <TableCell align="right">{row.count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>}

                </Table>
            </TableContainer>
        </>
    )
}

