import './App.css';
import { ProjectRoute } from './project-route';
import { Routes, Route } from "react-router-dom";
import { CreateProject } from './create-project';
import { ProjectDetail } from './project.-detail';
import { useState } from 'react';
import { Login } from '@linofischer/react-simple-login'

export const PASSWORD = 'timer2';
function App() {
  const [login, setLogin] = useState(false);
  return (
    <div className='p-2'>
      {login ? <Routes>
        <Route path="/" element={<ProjectRoute />} />
        <Route path="/create-project" element={<CreateProject />} />
        <Route path="/project/:id" element={<ProjectDetail />} />
      </Routes> :
        <Login credentials={{ password: PASSWORD, callback: setLogin, saveLoginStateToLocalStorage: true }} />
      }
    </div>
  );
}

export default App;
