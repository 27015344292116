// db.ts
import Dexie, { Table } from 'dexie';
import { Project } from '../model'

export class MySubClassedDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  projects!: Table<Project>; 

  constructor() {
    super('myDatabase');
    this.version(1).stores({
      projects: '++id, name' // Primary key and indexed props
    });
  }
}

export const db = new MySubClassedDexie();
