import { Button } from "@mui/material";
import { Process } from "../model";

export interface TimerButtonProps {
    process: Process;
    time: string;
    index: number;
    toggleTimer: (index: number) => void;
}
export function TimerButton({ process, time, index, toggleTimer }: TimerButtonProps) {
    return <Button
        variant="outlined"
        onClick={() => toggleTimer(index)}
        className={time != '0' ? "!bg-lime-200" : ""}>
        <div>
            <h1 className="text-black font-bold">{process.name}</h1>
            <div className="flex flex-row items-center">
            <p className={time != '0' ? "text-red-500" : "text-black"}>{time} </p>
            <p className="text-xs ml-1">s</p>
            </div>
        </div>
    </Button>

}