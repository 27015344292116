import { Button, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Process, Project, Time } from './model';
import { ProjectReader } from './project-detail-table';
import Divider from '@mui/material/Divider';
import { TimerButton } from './common/timer-button';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from './db/database';
import { exportExcelSheet, roundTime } from './common/utils';
import { notify, Toaster } from './common/snackbar';
import Edit from '@mui/icons-material/Edit';
import MenuBook from '@mui/icons-material/MenuBook';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DescriptionIcon from '@mui/icons-material/Description';
import Description from '@mui/icons-material/Description';
import { addMilliseconds } from 'date-fns';

export function ProjectDetail() {

    //const projects = useSelector((state: any) => state.projects.value);
    const projects = useLiveQuery(
        () => db.projects.toArray()
    ) ?? [];
    const location = useLocation();
    const currentId = location.pathname.split('/project/')[1];
    const currentProject: Project | undefined = useMemo(() => {
        return projects.find((project: Project) => project.id === currentId);
    }, [projects]);
    //const dispatch = useDispatch();
    const [currentProcessName, setCurrentProcessName] = useState<string>('');
    const [readerView, setReaderView] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(false);

    const [time, setTime] = useState<Time>({ duration: 0, startTime: new Date(), endTime: new Date() });
    const [running, setRunning] = useState(false);
    const [runCleanUpOnce, setRunCleanUpOnce] = useState(true);

    const timerIsRunning: boolean = useMemo(() => {
        return currentProject?.processes.some((process) => process.runningTimer === true) ?? false;
    }, [currentProject]);

    // set runningTimer false for all processes
    useEffect(() => {
        if (projects?.length > 0 && runCleanUpOnce) {
            cleanUpProject();
            setRunCleanUpOnce(false)
        }
    }, [projects])

    // timer
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (running) {
            const startDate = new Date();
            interval = setInterval(() => {
                setTime((prevTime) => {
                    return { ...prevTime, startTime: startDate, duration: prevTime.duration + 100, endTime: new Date() }
                });
            }, 100);
        } else if (!running) {
            setTime({duration: 0, startTime: new Date(), endTime: new Date()});
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);

        }
    }, [running]);

    // set running timer false for all processes
    async function cleanUpProject() {
        const processes = currentProject?.processes.map((process) => {
            return { ...process, runningTimer: false };
        });
        if (processes) {
            const updatedProject = { ...currentProject, processes };
            await db.projects.update(updatedProject as Project, updatedProject);
        }
    }

    async function updateCurrentProject() {
        if (currentProcessName !== '' || currentProcessName != null) {
            const projectToUpdate = {
                ...currentProject, processes: [...currentProject?.processes ?? [], {
                    name: currentProcessName,
                    times: [],
                    runningTimer: false,
                }
                ]
            };
            // dispatch(updateProject(projectToUpdate));
            await db.projects.update(projectToUpdate.id, projectToUpdate);
            setCurrentProcessName('');
        }
    }

    async function updateCurrentProjectData(project: Project) {
        await db.projects.update(project.id, project);
        notify('success', 'Project updated successfully.');
    }

    function switchToTimerView() {
        setReaderView(false);
    }

    function toggleEditMode() {
        if (editMode) {
            notify('info', 'Readonly mode.');
        } else {
            notify('info', 'Edit mode.');
        }
        setEditMode(!editMode);
    }

    // TODO: refactor -> make this beautiful 
    async function toggleTimer(clickedTimerIndex: number) {
        const currentRunningTimerIndex = currentProject?.processes.findIndex(process => process.runningTimer);
        const updatedProcesses = currentProject?.processes.map((process, iterateTimerIndex) => {
            if (currentRunningTimerIndex === -1) {
                setRunning(true);
                if (clickedTimerIndex === iterateTimerIndex) {
                    return { ...process, runningTimer: true }
                }
            } else {
                setRunning(false);
                if (clickedTimerIndex === iterateTimerIndex) {
                    if (clickedTimerIndex === currentRunningTimerIndex) {
                        return { ...process, runningTimer: false, times: [...process.times, {...time, endTime: new Date()}] }
                    } else {
                        setTimeout(() => {
                            setRunning(true);
                        })
                        return { ...process, runningTimer: true }
                    }
                }
                if (currentRunningTimerIndex === iterateTimerIndex) {
                    return { ...process, runningTimer: false, times: [...process.times, time] }
                }
            }
            return { ...process, runningTimer: false };
        });
        const updatedProject = { ...currentProject, processes: updatedProcesses };
        await db.projects.update(updatedProject as Project, updatedProject);
    }

    function stopTimer() {
        const currentRunningTimerIndex = currentProject?.processes.findIndex(process => process.runningTimer);
        if (currentRunningTimerIndex != null) {
            toggleTimer(currentRunningTimerIndex);
        }
    }

    return <>{currentProject && <div className='flex flex-col gap-y-2 px-2'>
        <Toaster></Toaster>
        {readerView &&
            <div className='flex justify-between'>
                <Link to="/" className='my-2 mr-2'>
                    <Button
                        className="my-1 w-50"
                        variant="outlined"
                        onClick={switchToTimerView}><ArrowBack /><span className="ml-1">Back</span></Button>
                </Link>
                <Button
                    className="my-1 w-50 !mr-2"
                    variant="outlined"
                    onClick={() => { exportExcelSheet(currentProject) }}><Description /><span className="ml-1">Excel Export</span></Button>
                <Button
                    variant="outlined"
                    onClick={toggleEditMode}
                >
                    {editMode ? <><MenuBook /><span className="ml-1">Readonly Mode</span></> :
                        <><Edit /><span className="ml-1">Edit Mode</span></>}</Button>
            </div>
        }
        {readerView ?
            <div> {editMode ?
                <div className='flex flex-col gap-y-2'>
                    <TextField
                        className='mb-1'
                        id="outlined-basic"
                        label="Project name"
                        variant="outlined"
                        defaultValue={currentProject.name}
                        onBlur={(event) => updateCurrentProjectData({ ...currentProject, name: event.target.value })} />
                    <TextField
                        id="outlined-basic"
                        label="Workstation"
                        variant="outlined"
                        defaultValue={currentProject.workstation}
                        onBlur={(event) => updateCurrentProjectData({ ...currentProject, workstation: event.target.value })} />
                    <TextField
                        id="outlined-basic"
                        label="Line"
                        variant="outlined"
                        defaultValue={currentProject.line}
                        onBlur={(event) => updateCurrentProjectData({ ...currentProject, line: event.target.value })} />
                    <TextField
                        id="outlined-basic"
                        label="Product"
                        variant="outlined"
                        defaultValue={currentProject.product}
                        onBlur={(event) => updateCurrentProjectData({ ...currentProject, product: event.target.value })} />
                    <TextField
                        id="outlined-basic"
                        label="Factory"
                        variant="outlined"
                        defaultValue={currentProject.factory}
                        onBlur={(event) => updateCurrentProjectData({ ...currentProject, factory: event.target.value })} />
                </div>
                :
                <div className="grid grid-cols-2 grid-cols-[repeat(2, minmax(0, 1fr))]">
                    <p>Project name:</p>
                    <p><b>{currentProject?.name}</b></p>
                    <p>Workstation:</p>
                    <p><b>{currentProject?.workstation}</b></p>
                    <p>Line:</p>
                    <p><b>{currentProject?.line}</b></p>
                    <p>Product:</p>
                    <p><b>{currentProject?.product}</b></p>
                    <p>Factory:</p>
                    <p><b>{currentProject?.factory}</b></p>
                </div>
            }

            </div> : <p className="text-xl text-center my-2">Current Project: <b>{currentProject!.name}</b></p>
        }


        {readerView ? <>
            {!editMode && <Button className="my-2 !p-8" variant="outlined" onClick={switchToTimerView}>Start timer</Button>
            }

            <Divider className="py-4" />

            <div className="flex flex-row gap-2 align-center">
                <TextField id="outlined-basic" label="Process name" variant="outlined"
                    value={currentProcessName}
                    onChange={(event) => setCurrentProcessName(event.target.value)} />
                <Button
                    variant="outlined"
                    onClick={updateCurrentProject}
                    disabled={(currentProcessName === '' || currentProcessName == null)}
                >Add process</Button>
            </div>
            <ProjectReader currentProject={currentProject} editMode={editMode}></ProjectReader>
        </>
            : <>
                <div className='grid grid-cols-2 gap-2 min-h-[70vh]'>
                    {currentProject.processes.map((process: Process, index: number) => {
                        return <TimerButton
                            key={index}
                            process={process}
                            index={index}
                            time={process.runningTimer ? roundTime(time.duration) : '0'} toggleTimer={toggleTimer}></TimerButton>
                    })}
                </div>
                {timerIsRunning ? <Button variant="outlined" onClick={stopTimer}>Stop Timer</Button>
                    : <Button variant="outlined" onClick={() => { setReaderView(true) }}><ArrowBack /><span className="ml-1">Back</span></Button>}
            </>}
    </div>} </>;
}
