import { createSlice } from '@reduxjs/toolkit'
import { Project } from '../model';

export const projectSlice = createSlice({
  name: 'projects',
  initialState: {
    value: [] as Project[],
  },
  reducers: {
    addProject: (state, action) => {
      state.value.push(action.payload);
    },
     updateProject: (state, action) => {
      state.value = state.value.map((project) => project.id === action.payload.id ? action.payload : project)
 
    
    },
  },
})

// Action creators are generated for each case reducer function
export const { addProject } = projectSlice.actions
export const { updateProject } = projectSlice.actions

export default projectSlice.reducer