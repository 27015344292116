import { Project } from './model';
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from './db/database';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmWindow, ConfrimWindowProps } from './common/dialog';
import { notify, Toaster } from './common/snackbar';

export function ProjectRoute() {
    //const projects = useSelector((state: any) => state.projects.value);
    const navigate = useNavigate();
    const projects = useLiveQuery(
        () => db.projects.toArray()
    );
    const [modalSettings, setModalSettings] = useState<ConfrimWindowProps>({
        message: '',
        callbackSuccess: () => console.log("success"),
        callbackCancel: () => console.log("cancel"),
        state: false
    });

    async function deleteProject(project: Project) {

        setModalSettings({
            message: `Do you really want to delete process "${project.name}"?`,
            state: true,
            callbackSuccess: async () => {
                await db.projects.delete(project.id);
                setModalSettings({ ...modalSettings, callbackCancel: () => { }, callbackSuccess: () => { }, state: false })
                notify('success', 'Project deleted successfully.');
            },
            callbackCancel: () => {
                setModalSettings({ ...modalSettings, callbackCancel: () => { }, callbackSuccess: () => { }, state: false })
            }
        });
    }

    const [search, setSearch] = useState('');
    return <div className='grid mx-2'>
        <Toaster></Toaster>
        <ConfirmWindow
            state={modalSettings.state}
            message={modalSettings.message}
            callbackSuccess={modalSettings.callbackSuccess}
            callbackCancel={modalSettings.callbackCancel}></ConfirmWindow>
        <h1 className="my-4"> Projects overview </h1>
        <div className="flex row gap-2">
            <TextField id="outlined-basic" label="search.." variant="outlined" onChange={(event) => setSearch(event.target.value)} />
            <Link to="create-project"><Button variant="outlined">Add project</Button></Link>
        </div>
        {(projects?.length ?? 0) > 0 &&
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
            >
                {projects?.filter(project => project.name.includes(search))?.map((project: Project) =>
                    <nav aria-label="secondary mailbox folders" key={project.id}>
                        <List>
                            <ListItem disablePadding
                                secondaryAction={
                                    <Button
                                        onClick={() => { deleteProject(project)}}
                                        className='!-mr-6'
                                    >
                                        <><DeleteIcon /></></Button>
                                } >
                                <ListItemButton onClick={() => navigate(`project/${project.id}`)}>
                                    <ListItemText className='mr-3' primary={`${project.name}: ${project.line} - ${project.workstation} - ${project.product} - ${project.factory}`}
                                    />
                                </ListItemButton>
                            </ListItem>

                        </List>




                    </nav>
                )}
            </List>
        }
    </div>
}