import { Alert, Snackbar } from "@mui/material"
import { createContext, useState } from "react"
import { toast, ToastContainer } from "react-toastify";

export const SnackBarContext = createContext(null as any)

export function Toaster({ children }: any) {
    return <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
}

export function notify(type: 'success' | 'info', text: string) {
    if (type === 'success') {
        toast.success(text, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    if (type === 'info') {
        toast(text, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}