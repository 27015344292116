import React, { useState } from 'react';
import { Project } from './model';
import { useDispatch } from 'react-redux';
import { addProject } from './store/project-slice';
import { Link, useNavigate } from 'react-router-dom';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { db } from './db/database';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useLiveQuery } from 'dexie-react-hooks';
import { getCopiedProjectData } from './common/utils';

export function CreateProject() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projects = useLiveQuery(
        () => db.projects.toArray()
    );

    const [newProject, setNewProject] = useState<Project>({
        id: String(new Date().getTime()),
        name: '',
        factory: '',
        workstation: '',
        line: '',
        product: '',
        processes: [],
    });

    async function saveProject() {
        dispatch(addProject(newProject));
        navigate(`/project/${newProject.id}`);
        await db.projects.add(newProject)
    };

    return <div className="flex flex-col gap-y-2 mx-2">
        <Link to="/" className='my-2 mx-2'>
            <Button
                className="my-1 w-50"
                variant="outlined"><ArrowBack /><span className="ml-1">Back</span></Button>
        </Link>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Use a project as a template</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Use a project as a template"
                onChange={(event) => setNewProject(
                    { ...getCopiedProjectData(projects, String(event.target.value)) as Project, id: newProject.id }
                )}
                defaultValue={1}
            >
                <MenuItem value={1}>- no template -</MenuItem>
                {projects?.map((project) =>
                    <MenuItem value={project.id} key={project.id}>{project.name}</MenuItem>
                )
                }
            </Select>
        </FormControl>
        <TextField InputLabelProps={{ shrink: true }} value={newProject.name} variant="outlined" label="Project name" required onChange={(event) => setNewProject({ ...newProject, name: event.target.value })}></TextField>
        <TextField InputLabelProps={{ shrink: true }} value={newProject.line} variant="outlined" label="Line" onChange={(event) => setNewProject({ ...newProject, line: event.target.value })}></TextField>
        <TextField InputLabelProps={{ shrink: true }} value={newProject.workstation} variant="outlined" label="Workstation" onChange={(event) => setNewProject({ ...newProject, workstation: event.target.value })}></TextField>
        <TextField InputLabelProps={{ shrink: true }} value={newProject.product} variant="outlined" label="Product" onChange={(event) => setNewProject({ ...newProject, product: event.target.value })}></TextField>
        <TextField InputLabelProps={{ shrink: true }} value={newProject.factory} variant="outlined" label="Factory" onChange={(event) => setNewProject({ ...newProject, factory: event.target.value })}></TextField>
        <Button variant="outlined" onClick={saveProject} disabled={newProject.name == null || newProject.name === ''}>Save project</Button>
    </div >
}